import React, { useCallback, useContext, useEffect, useState } from 'react';
import {GlobalButtonFinal, ModalsWrapper, ModalWindow} from "../GlobalStyles";
import {getCookie} from "cookies-next";

import * as Sentry from "@sentry/nextjs";
import { FormattedMessage } from 'react-intl';
import messages from '../../../../src/messages';
import { useRouter } from 'next/router';
import { LangContext } from '../../../../src/ConnectedIntlProvider';

export default function DialogDeleteGroups ({productDeleteUUID,    loadProducts, toggleCloseGroups,toggleDeleteGroups,editAllUUID,changeEditAllUUID,loadProductsFiltersAPI,triggerWarning,
                                      toggleDelete}) {

    const [alertShow, setAlertShow] = useState(false);
    const [alertTransitionShow, setAlertTransitionShow] = useState(false);
    const router = useRouter();
    const changeAlertShow2 = (variable) => {

        if(variable === true){

            setAlertShow(variable);
            setTimeout(function() {
                setAlertTransitionShow(true);
            },1);




        } else{


            setAlertTransitionShow(false);
            setTimeout(function() {
                setAlertShow(variable);
            },500);



        }

    };

    const [loadingDelete, setLoadingDelete] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [deleteTransitionShow, setDeleteTransitionShow] = useState(false);

    const changeDeleteShow = (variable) => {

        if(variable === true){

            setDeleteShow(variable);
            setTimeout(function() {
                setDeleteTransitionShow(true);
            },1);




        } else{


            setDeleteTransitionShow(false);
            setTimeout(function() {
                setDeleteShow(variable);
            },500);



        }

    };
    const deleteProductCheck = (productID) => {


        changeDeleteShow(true);
    };

    const [userTeamLoading, setUserTeamLoading] = useState(true);

    const [deleteState, setDeleteState] = useState(1);

    const [deleteParent, setDeleteParent] = useState("");
    const closeDelete  = () => {
        changeEditAllUUID(null);
        closeDeleteShow();
    }
    const showDelete  = (uuid) => {
        changeEditAllUUID(uuid);
    }

    const deleteGroup = (check, deleteProducts) => {

        var check2 = "true";

        if(check === false){
            check2 = "false";
        }


        var deleteProductsInner = "true";

        if(deleteProducts === false){
            deleteProductsInner = "false";
        }

        console.log("WTF", check2, deleteProducts,editAllUUID);
        fetch(process.env.NEXT_PUBLIC_OBALERO_API_BASEPATH+"groups?check="+check2+"&delete_products="+deleteProductsInner, {
            method: 'DELETE',
            headers: {
                'Content-type': 'application/json',
                'Authorization': "Bearer "+ getCookie('userTeam'), // notice the Bearer before your token
            },
            body: JSON.stringify(    [editAllUUID]),
        })
            .then(response => response.json())
            .then(function(data){
                if(data.message !== undefined && data.status !== 422){
                    Sentry.captureException("groups"+editAllUUID+": "+ data.message + " : " + getCookie("userMail"));
                    triggerWarning(data.message);
                }
                console.log("INNER", data, editAllUUID);
                if(data.status === 422){
                    console.log("shof", data, editAllUUID);
                    changeAlertShow2(true);
                }


                if(data.message === undefined && check2 && data.success !== "Groups were deleted."){
                    deleteGroup(false);
                    console.log("WTF", check2, editAllUUID);

                }
                if(data.message === undefined && !check2){
                    closeDelete();
                    console.log("WTF2", check2, editAllUUID);
                    loadProductsFiltersAPI();
                }

                if(data.success === "Groups were deleted."){
                    closeDelete();
                    console.log("hotovo", check2, editAllUUID);
                    loadProductsFiltersAPI();
                    loadProducts();
                }

            });

    }
    useEffect(() => {
                console.log(editAllUUID, "editAllUUID");
    }, [editAllUUID]);

    let closeDeleteShow = () => {
        changeDeleteShow(false);
        changeAlertShow2(false);

        setTimeout(function() {
            setLoadingDelete(false);
        },300);
    };
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const toggleDeleteInside = useCallback((): void => {
        if(showDeleteDialog){
            changeDeleteShow(false);
        }else{

            changeDeleteShow(true);
        }


    }, []);
    useEffect(() => {

        toggleDelete && toggleDelete(toggleDeleteInside);
    }, [toggleDelete, toggleDeleteInside]);

    const toggleDeleteGroupsInside = useCallback((): void => {
        console.log("FROM? TOGGLE");
                     deleteGroup(false);
    }, []);
    useEffect(() => {

        toggleDeleteGroups && toggleDeleteGroups(toggleDeleteGroupsInside);
    }, [toggleDeleteGroups, toggleDeleteGroupsInside]);


    const toggleCloseGroupsInside = useCallback((): void => {
        console.log("INSIDE");
        changeDeleteShow(false);
    }, []);
    useEffect(() => {

        toggleCloseGroups && toggleCloseGroups(toggleCloseGroupsInside);
    }, [toggleCloseGroups, toggleCloseGroupsInside]);



    const closeModalOutsideClick = (e) => {
        if( e.target.getAttribute("data-closemodal") === "true"){
            changeDeleteShow(false);
        }
        if( e.target.getAttribute("data-closemodal") === "true"){
            changeAlertShow2(false);
        }
    }
    const { language, setLanguage } = useContext(LangContext);


    return (
        <>

            <ModalsWrapper className={`deleteCheck  ${deleteTransitionShow ? 'showTransition' : 'hideTransition'} ${!deleteShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           style={{zIndex:"99999999999999999999"}}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center modal_dialog_small"}
                    id={'deleteProductDialog'} style={{ minHeight: 'unset',     height: "190px" }}>
                    <div className={'modal-header'}>
                        <h4>     <FormattedMessage {...messages.products_modal_delete_title}></FormattedMessage> </h4>

                    </div>


                        <div className={'modal-content shareProduct'} style={{ display: "block" }}>
                            <span> <FormattedMessage {...messages.products_modal_delete_description}></FormattedMessage> </span>

                            <div className={"modal-buttons"}>

                                <GlobalButtonFinal
                                                   className={"borderBlue btn-circle"}
                                                   onClick={() => closeDelete()}>
                                    <FormattedMessage {...messages.products_modal_delete_button_cancel}></FormattedMessage>
                                </GlobalButtonFinal>

                                <GlobalButtonFinal
                                                   className={"backgroundRed btn-circle"} data-uuid={productDeleteUUID}
                                                   onClick={deleteGroup}>
                                    <FormattedMessage {...messages.products_modal_delete_button_delete}></FormattedMessage>
                                </GlobalButtonFinal>

                            </div>


                        </div>





                </ModalWindow>


            </ModalsWrapper>

            <ModalsWrapper className={`alertCheck  ${alertTransitionShow ? 'showTransition' : 'hideTransition'}
             ${!alertShow ? 'hiddeComplete' : ''}`}
                           data-closemodal={true}
                           onClick={(e)=> closeModalOutsideClick(e)}
            >


                <ModalWindow
                    className={"center alert"}
                    id={'nefAlertProductDialog'}
                    style={{zIndex:"9999999999999999999"}}>

                    <div className={'modal-header'}>


                        <h4 className={'warning'}>
                            <FormattedMessage {...messages.modal_alert_title_warning}></FormattedMessage></h4>

                        <span style={{
                            fontSize: '15px',
                            color: 'var(--black-60, #666)',
                            paddingBottom: '15px',
                            width: '100%',
                            marginTop: '15px',
                            marginBottom: '15px',
                        }}>{language == "cs" ?
                        "Uvnitř této skupiny se nachází produkty, chcete je smazat také a smazat skupinu a produkty ponechat ? " :
                            "Inside of this groups are products, do you want to also delete them or just delete group and leave products ? "}</span>


                    </div>


                    <div className={'modal-content shareProduct deleteGroupsCheck'} style={{ display: 'block' }}>

                        <div className={'modal-buttons'}>
                            <GlobalButtonFinal style={{ width: 'fit-content' }} className={'borderBlue btn-circle'}
                                               onClick={() => deleteGroup(false)}>
                                <FormattedMessage {...messages.modal_alert_buttons_delete_with_products}></FormattedMessage>
                            </GlobalButtonFinal>
                            <GlobalButtonFinal style={{ width: "fit-content", marginLeft: "10px" }}
                                               className={"borderBlue btn-circle"}
                                               onClick={() => deleteGroup(false, false)}>
                                <FormattedMessage {...messages.modal_alert_buttons_delete_without_products}></FormattedMessage>
                            </GlobalButtonFinal>
                            <GlobalButtonFinal style={{ width: "fit-content", marginLeft: "10px" }}
                                               className={"borderBlue btn-circle"}
                                               onClick={() => closeDeleteShow()}>
                                <FormattedMessage {...messages.modal_alert_buttons_cancel}></FormattedMessage>
                            </GlobalButtonFinal>

                        </div>

                    </div>
                </ModalWindow>


            </ModalsWrapper>


        </>
    )
}